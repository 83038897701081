import ScreenBox from "./components/ScreenBox";
import MatrixBackground from "./components/Matrix";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Navbar from "./components/Navbar";


export default function App() {
  //bg-gradient-to-r from-green-300 via-blue-500 to-purple-600
  const bgColorfull = "bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
  const bg = "bg-black"
  return (
    <div className={`App h-screen w-screen`}>
      <MatrixBackground />
      <BrowserRouter >
        <Routes>
          <Route path="*" element={<ScreenBox location="/404" />} />

          <Route path="/" element={<ScreenBox location="/" />} />
          <Route path="/work" element={<ScreenBox location="/work" />} />
          <Route path="/about" element={<ScreenBox location="/about" />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes >
      </BrowserRouter>

    </div>
  );
}

