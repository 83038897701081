import { useState, useEffect, useRef } from "react";

export default function Home() {
    const [kryptagora, setKryptagora] = useState("Kryptagora")
    const kryptagoraRef = useRef()
    kryptagoraRef.current = kryptagora
    const [cursive, setCursive] = useState(false)

    useEffect(() => {
        // create interval
        const interval = setInterval(
            changeKryptagora,
            1200
        );

        // clean up interval on unmount
        return () => {
            clearInterval(interval);
        };
    }, []);


    function changeKryptagora() {
        // change letters or reset the text to "Kryptagora"
        var d = Math.random()
        if (d < 0.2) {
            setKryptagora(prev => prev.replace("a", "4"))
        } else if (d < 0.4) {
            setKryptagora(prev => setCharAt(prev, Math.floor(Math.random() * prev.length), '_'))
        } else if (((d < 1) && (kryptagoraRef.current !== "Kryptagora")) || (stringDifference(kryptagoraRef.current, "Kryptagora") > 2)) {
            setCursive(true)
            setTimeout(() => {
                setCursive(false)
                setKryptagora("Kryptagora")
            }, 400)
        }
    }

    function stringDifference(s1, s2) {
        var distance = 0;
        for (var i = 0; i < s1.length; i++) {
            if (s1[i] !== s2[i]) {
                distance += 1
            }
        }
        return distance
    }

    function setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }


    return (
        <div className="flex justify-center items-center min-h-full">
            <div>
                {/* Kryptagora Part */}
                <div className="text-[30px] md:text-[50px] font-extrabold">
                    <p className="text-white">Hello, I'm <span className={`text-[45px] md:text-[55px] bg-clip-text text-transparent bg-gradient-to-r from-violet-600 to-pink-600 tracking-wide font-black select-none font-mono ${cursive && "italic"}`}>
                        {kryptagora}
                    </span>.</p>
                </div>


                <p className="text-xs md:text-sm text-white mt-0 uppercase font-medium tracking-tight overline text-zinc-300 select-none -top-2 relative
                            hover:text-zinc-100">Data Science • Informatics • Web Development</p>

                <ul className="text-white mt-2 text-lg text-zinc-300 select-none -top-2 relative ">
                    <li className="hover:text-zinc-100 flex items-center">
                        <span className="pr-1 text-purple-600"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525" />
                        </svg>
                        </span>Based in Europe</li>

                    <li className="hover:text-zinc-100 flex items-center">
                        <span className="pr-1 text-purple-600"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                        </svg>
                        </span>Self Employed</li>

                    <li className="hover:text-zinc-100 flex items-center">
                        <span className="pr-1 text-purple-600"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                        </svg>
                        </span>Graduate at JKU Linz</li>

                    <li className="hover:text-zinc-100 flex items-center">
                        <span className="pr-1 text-purple-600"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>
                        </span>amon@mailbox.org</li>
                </ul>
            </div>

        </div>)
}



