import { useState } from "react";
import { useEffect } from "react";
import DataScienceSection from "../components/aboutSections/DataScience";
import BioinformaticsSection from "../components/aboutSections/Bioinformatics";
import WebSection from "../components/aboutSections/WebSection";
import BlockchainSection from "../components/aboutSections/BlockchainSection";
import CyberSecSection from "../components/aboutSections/CyberSecuritySection";

export default function About() {

    // Gives the width of the widget besides text
    const logoWidth = "w-32"

    return(
        <div className="text-white mt-16 md:m-16">
                <p className="md:text-lg">Hello, I'm Amon. My journey through the digital landscape has cultivated a diverse and robust skill set. Here's a snapshot of what I've delved into: </p>
                <DataScienceSection logoWidth={logoWidth}/>
                <BioinformaticsSection logoWidth={logoWidth}/>
                <WebSection logoWidth={logoWidth}/>
                <CyberSecSection logoWidth={logoWidth}/>
                <BlockchainSection logoWidth={logoWidth}/>     
                <div className="h-12"></div>       
        </div>
    )
}