import { useState, useEffect, useRef } from "react";
import './Work.css';


export default function Work() {
    const [description, setDescription] = useState("My work is a culmination of dedication, innovation, and expertise.")
    const descriptionRef = useRef()
    descriptionRef.current = description
    const [glowWord, setGlowWord] = useState("")

    useEffect(() => {
        // create interval
        const interval = setInterval(
            changeDescription,
            1500
        );

        // clean up interval on unmount
        return () => {
            clearInterval(interval);
        };
    }, []);

    function changeDescription() {
        // change words or reset the text to the original description
        var r = Math.random()
        if (r < 0.2) {
            setDescription(prev => prev.replace("dedication", "passion"))
            setGlowWord("passion")
        } else if (r < 0.4) {
            setDescription(prev => prev.replace("innovation", "creativity"))
            setGlowWord("creativity")
        } else if (((r < 1) && (descriptionRef.current !== "My work is a culmination of dedication, innovation, and expertise.")) || (stringDifference(descriptionRef.current, "My work is a culmination of dedication, innovation, and expertise.") > 5)) {
            setDescription("My work is a culmination of dedication, innovation, and expertise.")
            setGlowWord("")
        }
    }

    // Reuse the stringDifference function you provided
    function stringDifference(s1, s2) {
        var distance = 0;
        for (var i = 0; i < s1.length; i++) {
            if (s1[i] !== s2[i]) {
                distance += 1
            }
        }
        return distance
    }

    function workElementBig(header, link, subtitle, text, linktrue) {
        return (
            <div className="hidden md:flex pt-4">
                <div className="Title and subtitle p-4  min-w-[300px] flex flex-col items-center justify-center">
                    <h1 className="bg-clip-text text-transparent bg-gradient-to-r from-violet-600 to-pink-600 font-bold text-2xl text-center"><a href={link} target={linktrue ? "_blank": ""}>{header}</a></h1>
                    <h2 className="text-gray-400 text-center">{subtitle}</h2>
                </div>
                <div className="Description p-4 text-justify">{text}</div>
            </div>
        )
    }

    function workElementMobile(header, link, subtitle, text, linktrue) {
        return (
            <div className="flex md:hidden pt-4 flex-col">
                <div className="Title and subtitle p-4  min-w-[300px] flex flex-col items-center justify-center">
                    <h1 className="bg-clip-text text-transparent bg-gradient-to-r from-violet-600 to-pink-600 font-bold text-2xl text-center"><a href={link} target={linktrue ? "_blank": ""}>{header}</a></h1>
                    <h2 className="text-gray-400 text-center">{subtitle}</h2>
                </div>
                <div className="Description p-4 text-justify">{text}</div>
                <hr className="opacity-20"></hr>
            </div>
        )
    }

    const data = [
        {
            header: {
                title: "Bachelors Thesis",
                link: "https://github.com/Kryptagora/JazzNet"
            },
            subtitle: "Jazz Chord Generation with Autoregressive Neural Networks",
            description: "Using modern LSTM architectures, a neural network has been trained on a dataset of jazz chords. These chords were simplified prior to training with advanced \
            regex and pattern detection systems to reduce the vocabulary size. Afterwards, the network generated new chord sequences which were arranged according to their corresponding durations. This process \
            ultimately yielded new melodic patterns that closely mimic the training data."
        },
        {
            header: {
                title: "Draw and Predict",
                link: "https://github.com/Kryptagora/Deep-Learning-Training-Interface"
            },
            subtitle: "Predict Numbers Drawn by Users",
            description: "Harnessing the capabilities of multiple CNN layers, this network is meticulously trained on the MNIST dataset. Users can draw numbers directly within the interface, \
            crafted using Tkinter, to predict the digit they have drawn. This project further offers the flexibility to adjust hyperparameters, including learning rate, batch size, and others. It provides real-time training \
            information, allowing users to experiment with different settings."
        },
        {
            header: {
                title: "Blosum Calculator",
                link: "https://github.com/Kryptagora/pysum"
            },
            subtitle: "Calculating Log-Odds Ratio of Two given Sequences",
            description: "This tool, developed using Tkinter, is designed to efficiently calculate the log-odds ratio of two provided sequences. While it can handle various types of sequences, it is \
            particularly suited for amino acid, DNA, or RNA sequence information. Users have the flexibility to set the calculation degree. The methodology for this calculation is based on Dr. Sepp Hochreiter's script from Bioinformatics I."
        },
        {
            header: {
                title: "NFT Minter",
                link: "https://github.com/Kryptagora/cardano-minter-collection"
            },
            subtitle: "Javascript NFT Minter for the Cardano Blockchain",
            description: "When NFTs were just a fresh concept, a team of enthusiastic developers and I created an NFT minter that communicated directly with the Cardano blockchain to mint them. This was done using JavaScript and minimal documentation. The main \
            challenge in this project was figuring out how to work with the blockchain and setting standards as well as making them public to the masses. Dedicated servers were managed with nodes and CLI only."
        },
        {
            header: {
                title: "This Site :)",
                link: "#"
            },
            subtitle: "Portfolio Website using TailwindCSS and ReactJS",
            description: "This website was built entirely from scratch, with no templates used. The background matrix isn't an SVG, but a JavaScript creation. See if you can find the repeating letters! This is just one of the many user-friendly websites I've designed and created."
        }
    ];

    const baThesis = workElementBig(data[0].header.title, data[0].header.link, data[0].subtitle, data[0].description, true)
    const drawAndPredict = workElementBig(data[1].header.title, data[1].header.link, data[1].subtitle, data[1].description, true)
    const Blosum = workElementBig(data[2].header.title, data[2].header.link, data[2].subtitle, data[2].description, true)
    const cardanoNFT = workElementBig(data[3].header.title, data[3].header.link, data[3].subtitle, data[3].description, true)
    const thisSite = workElementBig(data[4].header.title, data[4].header.link, data[4].subtitle, data[4].description, false)

    const baThesisMobile = workElementMobile(data[0].header.title, data[0].header.link, data[0].subtitle, data[0].description, true)
    const drawAndPredictMobile = workElementMobile(data[1].header.title, data[1].header.link, data[1].subtitle, data[1].description, true)
    const BlosumMobile = workElementMobile(data[2].header.title, data[2].header.link, data[2].subtitle, data[2].description, true)
    const cardanoNFTMobile = workElementMobile(data[3].header.title, data[3].header.link, data[3].subtitle, data[3].description, true)
    const thisSiteMobile = workElementMobile(data[4].header.title, data[4].header.link, data[4].subtitle, data[4].description, false)


    return (
        <div className="text-white pt-16">
            <p className="text-lg leading-relaxed w-full text-center">
                {description.split(" ").map(word => {
                    if (word === glowWord) {
                        return <span className="text-violet-600">{word} </span>
                    } else {
                        return word + " "
                    }
                })}
            </p>

            {baThesis}
            {drawAndPredict}
            {Blosum}
            {cardanoNFT}
            {thisSite}
            {baThesisMobile}
            {drawAndPredictMobile}
            {BlosumMobile}
            {cardanoNFTMobile}
            {thisSiteMobile}
        </div>
    )
}

