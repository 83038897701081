import { useEffect, useState } from "react";

export default function DataScienceSection(props) {
    const [matrix, setMatrix] = useState(generateMatrix())

    useEffect(() => {
        const matrixInterval = setInterval(
            () => setMatrix(generateMatrix()),
            1000
        );

        return () => {
            clearInterval(matrixInterval);
        };
    }, [])

    function generateMatrix() {
        let matrix = [];
        for (let i = 0; i < 4; i++) {
            matrix.push([]);
            for (let j = 0; j < 4; j++) {
                matrix[i].push(Math.floor(Math.random() * 2));
            }
        }
        return matrix;
    }

    function RendersMatrixToHTML(matrix) {
        return matrix.map((row, idx) => {
            return (
                <div key={idx} className="flex justify-center items-center">
                    {row.map((item, idx) => {
                        return (
                            <div key={idx} className="p-1">
                                <div className={`h-3 w-3 rounded-full ${item === 1 ? "bg-white" : "bg-black"}`}></div>
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    const matrixElem = RendersMatrixToHTML(matrix)

    return (
        <div className="mt-8 flex rounded items-center">
            <div className={`mr-1 md:mr-0 ${props.logoWidth}`}>
                {matrixElem}
            </div>

            <div>
                <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-violet-600 to-pink-600 font-bold text-2xl w-max">Data Science</h2>
                <p>Data isn't just figures; it's a tool for understanding and decision-making. Through rigorous analysis, I derive meaningful insights, guiding businesses and projects toward informed strategies.</p>
            </div>


        </div>

    )
}