import React, { useEffect, useRef, useState } from 'react';

function MatrixBackground({ timeout = 90 }) {
    const canvas = useRef();

    const [width, setWidth] = useState(document.body.offsetWidth);
    const [height, setHeight] = useState(document.body.offsetHeight);

    const darkMode = true

    const textChars = "KRYPTAGORA4X0_アイウエオカキクケコサシス                        "
    //const textChars = "GTAC  "

    const handleWindowSizeChange = () => {
        setWidth(document.body.offsetWidth);
        setHeight(document.body.offsetHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);


    useEffect(() => {
        const context = canvas.current.getContext('2d');

        const width = document.body.offsetWidth;
        const height = document.body.offsetHeight;
        canvas.current.width = width;
        canvas.current.height = height;

        context.fillStyle = darkMode ? '#000': 'white';
        context.fillRect(0, 0, width, height);

        const columns = Math.floor(width / 20) + 1;
        const yPositions = Array.from({ length: columns }).fill(0);

        // slowly laers letters
        context.fillStyle = darkMode ? '#000': 'white';
        context.fillRect(0, 0, width, height);

        const matrixEffect = () => {
            context.fillStyle = darkMode ? '#0003': '#fff3';
            context.fillRect(0, 0, width, height);

            context.fillStyle = '#db2777';
            context.font = '11pt monospace';
            


            yPositions.forEach((y, index) => {
                //const text = String.fromCharCode(Math.random() * 128);
                const text = textChars[Math.floor(Math.random() * textChars.length)]
                const x = index * 20;
                context.fillText(text, x, y);

                if (y > 100 + Math.random() * 10000) {
                    yPositions[index] = 0;
                } else {
                    yPositions[index] = y + 20;
                }
            });
        };

        const interval = setInterval(matrixEffect, timeout);
        return () => {
            clearInterval(interval);
        };
    }, [canvas, timeout, width, height]);

    return (
        <div
            style={{
                background: darkMode ? '#000000': '#ffff',
                overflow: 'hidden',
                position: 'fixed',
                height: '100%',
                width: '100%',
                zIndex: -1,
                left: '0',
                top: '0',
            }}
        >
            <canvas
                ref={canvas}
            />
        </div>
    );
}

export default MatrixBackground;