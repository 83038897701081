import { useEffect, useState } from "react";


export default function BlockchainSection(props) {


    return (
        <div className="mt-8 flex items-center">

            <div className={`w-94 md:${props.logoWidth}`}>
                <img className="" src="https://bafybeihnwwxczew2i32pjai6xlkaz6hhd2v6nvk72j5kn4hwe4qtmb437q.ipfs.nftstorage.link/" />
            </div>


            <div>
                <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-gray-600 to-gray-50 font-bold text-2xl w-max">Blockchain and web3</h2>
                <p>The digital frontier is ever-evolving, and the decentralized web is its latest chapter. My engagement with blockchain and web3 technologies positions me to leverage the benefits of decentralized systems, ensuring secure and innovative solutions.</p>
            </div>




        </div>

    )
}