import { useEffect, useState } from "react";
import htmlLogo from "../../img/html.svg";
import cssLogo from "../../img/css.svg";
import jsLogo from "../../img/javascript.svg";

export default function WebSection(props) {
    const [logo, setLogo] = useState(htmlLogo);

    useEffect(() => {
        // delay the first execution by 500ms 
        const timeout = setTimeout(() => {
            const interval = setInterval(() => {
                setLogo(prev => {
                    if (prev === htmlLogo) {
                        return cssLogo;
                    } else if (prev === cssLogo) {
                        return jsLogo;
                    } else {
                        return htmlLogo;
                    }
                })
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }, 500);
        return () => {
            clearTimeout(timeout);
        };
    }, [])
    
    

    return (
        <div className="mt-8 flex items-center">
            <div className="mr-4">
                <img className={`w-96 md:w-24 animate-bounce`} src={logo} />
            </div>

            <div>
                <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-amber-300 font-bold text-2xl w-max">Web Development</h2>
                <p>Digital presence is paramount in today's age. My proficiency in web development ensures that websites are not only aesthetically pleasing but also functional, user-friendly, and optimized for performance.</p>
            </div>


        </div>

    )
}