import Skill from "./Skill";
import Home from "../routes/Home";
import Navbar from "./Navbar";
import NotFound from "../routes/NotFound";
import Work from "../routes/Work";
import About from "../routes/About";


export default function ScreenBox(props) {
    return (
        <div className="h-full flex justify-center items-center">
            <div className="h-full 2xl:w-[1536px] w-full md:p-20 p-3 ">
                <div className="relative h-full w-full">
                    {/* Background blur */}
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-pink-600 to-purple-600 rounded-lg blur opacity-75"></div>

                    {/* Actual box over it */}
                    <div className="relative h-full bg-black rounded-lg">
                        {/* Content here */}
                        {/* Added overflow-y-auto to this div */}
                        <div className="pt-0 p-0 h-full overflow-y-auto">
                            <div className="sticky top-0">
                                <Navbar />
                            </div>
                            {/* Area under Navbar */}
                            <div className="h-full overflow-y-auto p-4">
                                {props.location === "/" && <Home />}
                                {props.location === "/404" && <NotFound />}
                                {props.location === "/about" && <About />}
                                {props.location === "/work" && <Work />}

                                {/* Footer */}
                            <div className="absolute bottom-0 right-0 px-2 py-1">
                                <p className="text-white text-sm bg-black rounded-lg px-2 bg-opacity-80">©Kryptagora 2023</p>
                            </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
