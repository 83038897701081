import { useEffect, useState } from "react";

export default function BioinformaticsSection(props) {

    const [matrix, setMatrix] = useState(generateATCGMatrix());

    useEffect(() => {
        const matrixInterval = setInterval(
            mutateMatrix,
            200
        );

        return () => {
            clearInterval(matrixInterval);
        };
    }, [])

    
    function generateATCGMatrix() {
        let matrix = [];
        for (let i = 0; i < 6; i++) {
            let row = [];
            for (let j = 0; j < 6; j++) {
                row.push("ATCG"[Math.floor(Math.random() * 4)]);
            }
            matrix.push(row);
        }
        return matrix;
    }

    function mutateMatrix() {
        setMatrix(prev => {
            let array = prev.slice()
            let row = Math.floor(Math.random() * 6);
            let column = Math.floor(Math.random() * 6);
            array[row][column] = "ATCG"[Math.floor(Math.random() * 4)];
            return array
        })
    }



    function renderMatrix(matrix) {
        return matrix.map((row, index) => {
            return (
                <div key={index} className="flex justify-center items-center">
                    {row.map((item, index) => {
                        return (
                            <div className="" key={index}>
                                <div className={`h-3 w-3 rounded-full ${item === "A" ? "text-green-500" : item === "T" ? "text-red-500" : item === "C" ? "text-yellow-500" : "text-blue-500"}`}><p className="text-xs font-bold font-mono">{item}</p></div>
                                
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    const matrixelem = renderMatrix(matrix);

    return (
        <div className="mt-8 flex items-center">
            

            <div>
                <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-green-600 to-lime-400 font-bold text-2xl w-max">Bioinformatics</h2>
                <p>At the intersection of biology and technology, I navigate the intricacies of molecular data. My expertise in bioinformatics aids in drawing meaningful conclusions from complex biological data sets, pushing the boundaries of modern science.</p>
            </div>

            <div className={`${props.logoWidth}`}>
                {matrixelem}
            </div>

        </div>

    )
}